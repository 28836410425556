import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./theme/darkTheme.ts";
import "./theme/lightTheme.ts";
import "./theme/varialble.ts";
import "./components/CustomContainer.tsx";
import "./components/CustomImage.tsx";
import "./components/CustomLink.tsx";
import "./components/CustomText.tsx";
import "./components/GoogleApiWrapper.tsx";
import "./components/Logo.tsx";
import "./components/layouts/Footer.tsx";
import "./components/layouts/Header.tsx";
import "./components/layouts/HtmlContainer.tsx";
import "./components/layouts/index.tsx";
import "./styles/components/base.scss";
import "./styles/components/font.scss";
import "./styles/components/scroll.scss";
//import './client.js';  // <- Add this line if client.js is in the src folder

const root = (ReactDOM as any).createRoot(
  document.getElementById("root") as HTMLElement
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
